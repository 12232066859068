const activeEnv = process.env.GATSBY_ACTIVE_ENV || 'dev';

const urls = {
  prod: {
    apiUrl: 'https://api.porowneo.pl',
    apiv2: 'https://api-platform.porowneo.pl',
    wwwUrl: 'https://porowneo.pl',
    calcUrl: 'https://samochod.porowneo.pl/form',
  },
  test: {
    apiUrl: 'https://api.test.ktws.io',
    apiv2: 'https://api-platform.test.ktws.io',
    wwwUrl: 'https://porowneo.test.ktws.io',
    calcUrl: 'https://samochod.test.ktws.io/form',
  },
  local: {
    apiUrl: 'https://api.test.ktws.io',
    apiv2: 'http://apiv2.local.ktws.io',
    wwwUrl: 'http://localhost:3000',
    calcUrl: 'https://samochod.porowneo.pl/form',
  },
};

const getApiUrl = (env: string) => {
  switch (env) {
  case 'prod':
    return urls.prod;
  case 'test':
    return urls.test;
  default:
    return urls.local;
  }
};

export const KT_API_URL = getApiUrl(activeEnv).apiUrl;
export const API_V2 = getApiUrl(activeEnv).apiv2;
export const WWW_URL = getApiUrl(activeEnv).wwwUrl;
export const CALC_URL = getApiUrl(activeEnv).calcUrl;

const objectToQueryString = (obj: any) => Object.keys(obj).map(key => `${key}=${obj[key]}`).join('&');

const generateErrorResponse = (message: any) => ({
  message,
  status: 'error',
});

async function request(path: string, params: any, method: 'GET' | 'POST'): Promise<any> {
  const options: any = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
  };

  let url = path;

  if (params) {
    if (method === 'GET') {
      url += `?${objectToQueryString(params)}`;
    } else {
      options.body = JSON.stringify(params);
    }
  }

  const response = await fetch(url, options);

  if (response.status !== 200) {
    return generateErrorResponse('The server responded with an unexpected status.');
  }

  return response.json();
}

export const ktApi = {
  get(url: string, params?: any) {
    return request(`${KT_API_URL}${url}`, params, 'GET');
  },
  post(url: string, params?: any) {
    return request(`${KT_API_URL}${url}`, params, 'POST');
  },
};

export const createUrlPhoneValidation = () => `${KT_API_URL}/api/phone/validation`;

export const createUrlGetAgreements = () => `${API_V2}/api/consents/active`;
