import React from 'react';
import styles from '../../styles/scss/libraries/bootstrap.module.scss';

export const Logos = () => (
  <div className="partners-section">
    <div className={styles.container}>
      <div className="flex-section">
        <span>Nasi partnerzy:</span>
        <img src="/lp/aviva.png" alt="aviva" />
        <img src="/lp/link4.png" alt="link4" />
        <img style={{ width: 30, height: 30 }} src="/lp/uniqa.svg" alt="uniqa" />
        <img src="/lp/generali.png" alt="generali" />
        <img src="/lp/euroins.png" alt="euroins" />
        <img src="/lp/mtu24.png" alt="mtu24" />
        <img src="/lp/proame.png" alt="proame" />
        <img src="/lp/YCDEH.png" alt="YCDEH" />
        <img src="/lp/PZU.png" alt="PZU" />
        <img src="/lp/benefia.png" alt="benefia" />
        <img src="/lp/tue.png" alt="tue" />
      </div>
    </div>
  </div>
);
