import axios from 'axios';

import {
  IAgreementsPayload, IAgreementsValues, IAgreementWithOred, IAgrement,
} from './types';
import { getCookieByName, getParameterByName } from './utils';
import { getAgreementName, REQUIRED_AGREEMENTS } from './config';
import { createUrlPhoneValidation } from './url';

export const createAgreementsPayload = (agreements: IAgreementWithOred[], agreementsValues: IAgreementsValues) => {
  const agreementsPayload: IAgreementsPayload = {};
  const checkedAgreements: string[] = [];
  Object.keys(agreementsValues).forEach(agreeVal => agreementsValues[agreeVal] && checkedAgreements.push(agreeVal));
  if (checkedAgreements.length > 0) {
    checkedAgreements.forEach((agreement) => {
      if (agreement) {
        const agreementData = agreements.find(agree => agree.name === agreement);
        if (agreementData) {
          agreementsPayload[agreementData.id] = {
            id: agreementData.id,
            value: true,
            source: 'MOTOR',
          };
        }
      }
    });
  }
  return agreementsPayload;
};

const getCBZ = () => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    return window?.PorowneoFormConfig?.cbz_kampania;
  }
  return undefined;
};

export const createPayload = (name: string, phone: string, agreementsValues: IAgreementsValues, agreements: IAgreementWithOred[]) => ({
  imie: name,
  tel: phone,
  cbz_kampania: getCBZ() || '',
  referer: window.location.href,
  utm_medium: getParameterByName('utm_medium'),
  utm_source: getParameterByName('utm_source'),
  cbz_phone: getParameterByName('aff_id'),
  cbz_nr_pracownika: getParameterByName('html_version'),
  cbz_samochod_1: getParameterByName('utm_product'),
  cbz_samochod_2: getParameterByName('utm_campaign'),
  cbz_samochod_3: getParameterByName('utm_source'),
  sub_id: getParameterByName('sub_id'),
  ga: getCookieByName('_ga'),
  cbz_option: `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`,
  all_params: window.location.search,
  agreements: createAgreementsPayload(agreements, agreementsValues),
});

export const setMask = (maxLength: number, symbolMask: RegExp) => {
  const mask = [];
  for (let i = 0; i < maxLength; i += 1) {
    mask.push(symbolMask);
  }
  return mask;
};

let validPhones: string[] = [];
let invalidPhones: string[] = [];

export const isPhoneHLR = async (stringPhone: any) => {
  if (validPhones.includes(stringPhone)) {
    return true;
  }
  if (invalidPhones.includes(stringPhone)) {
    return false;
  }

  if (stringPhone && stringPhone.length === 9) {
    return axios
      .post(createUrlPhoneValidation(), { number: stringPhone.replace(/\s/g, '') }, { timeout: 6000 })
      .then((responseJson) => {
        if (responseJson?.data?.valid) {
          validPhones = [...new Set([...validPhones, stringPhone])];
        }
        invalidPhones = [...new Set([...validPhones, stringPhone])];

        return responseJson?.data?.valid;
      })
      .catch(() => false);
  }
};

export const setAgreementsOrder = (agreements: IAgrement[]) => {
  const modifiedAgreements: IAgreementWithOred[] = [];

  agreements.forEach((agreement) => {
    if (REQUIRED_AGREEMENTS.includes(agreement.name)) {
      modifiedAgreements.push({ ...agreement, order: getAgreementName(agreement.name) });
    }
  });
  return modifiedAgreements;
};

export const setGclid = () => {
  const gclid = getParameterByName('gclid');
  return gclid ? `?gclid=${encodeURIComponent(gclid)}` : '';
};
