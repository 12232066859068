import React from 'react';

interface IModalProps {
  isModalShown: boolean;
}

export const Modal = ({ isModalShown }: IModalProps) => {
  if (isModalShown && window && window.dataLayer) {
    const cbzId = sessionStorage.getItem('CBZ_ID');
    window.dataLayer.push({
      event: 'Lead wyslany',
      eventAction: 'Moto',
      eventCategory: 'Lead',
      eventLabel: 'LP',
      leadId: cbzId || '',
    });
  }
  return (
    isModalShown ? (
      <div className="mautic-landing-page">
        <div className="mautic-landing-page__content">
          <div className="shad_wrapper mautic-center-me">
            <div className="logo-section" style={{ paddingTop: '40px' }}>
              <div className="logo-flex" style={{ justifyContent: 'center' }}>
                <img src="/lp/logo-color.png" alt="Porowneo.pl" className="logo" />
              </div>
            </div>
            <h1 className="mautic-title-modal" style={{ marginTop: '30px' }}>Dziękujemy.</h1>
            <h2 className="mautic-subtitle-modal">
              Niebawem
              <br />
              skontaktujemy się z Tobą.
              <br />
              <br />
              <strong style={{ color: '#2453ff' }}>Miłego dnia!</strong>
              <br />
              <br />
            </h2>
          </div>
        </div>
      </div>
    ) : null);
};
