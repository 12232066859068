import React from 'react';
import styles from '../../styles/scss/libraries/bootstrap.module.scss';
import { WWW_URL } from './url';
import { getSearchParams } from './utils';

export const FooterLp = () => (
  <footer className="mautic-footer">
    <div className={styles.container}>
      <p>
        Niniejszy materiał ma charakter marketingowy i nie stanowi oferty w rozumieniu ustawy z dnia 23
        kwietnia 1964 r. - Kodeks cywilny.
      </p>
      <p>
        Porowneo.pl Sp. z o.o. z siedzibą w Warszawie (00-189) przy ul. Inflanckiej 4B, wpisana do rejestru
        przedsiębiorców prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XII
        Wydział Gospodarczy KRS pod numerem: 0000439594, NIP: 5213640335, o kapitale zakładowym: 5.000
        PLN - jest agentem ubezpieczeniowym wykonującym za wynagrodzeniem dystrybucję ubezpieczeń
        na rzecz więcej niż jednego zakładu ubezpieczeń w zakresie tego samego działu
        ubezpieczeń, zgodnie z załącznikiem do ustawy z dnia 11 września 2015 r. o
        działalności ubezpieczeniowej i reasekuracyjnej.
      </p>
      <p>
        Aktualna lista towarzystw współpracujących z Porowneo.pl Sp. z o.o. dostępna jest
        na
        {' '}
        <a href={`${WWW_URL}/${getSearchParams()}`} target="_blank" rel="noopener noreferrer">www.porowneo.pl</a>
      </p>
      <p>
        Administratorem Pani/a danych osobowych jest Porowneo.pl Sp. z o.o. z siedzibą w Warszawie przy ul.
        Inflanckiej 4B, 00-189 Warszawa, nr KRS: 0000439594. Administrator wyznaczył Inspektora Ochrony
        Danych, z którym mogą się Państwo skontaktować pisemnie (na adres siedziby
        Administratora) lub elektronicznie tj. za pośrednictwem adresu poczty elektronicznej:
        ado@porowneo.pl. Jednocześnie informujemy, że przysługuje Państwu prawo do
        żądania dostępu do danych osobowych, ich sprostowania, usunięcia lub ograniczenia
        przetwarzania jak również prawo do wniesienia sprzeciwu wobec przetwarzania, a także
        przenoszenia danych. Ponadto mają Państwo prawo wnieść skargę do Prezesa
        Urzędu Ochrony Danych Osobowych w związku z przetwarzaniem przez Administratora Państwa
        danych osobowych. Szczegółowe informacje na temat podstawy prawnej przetwarzania
        Państwa danych osobowych, podmiotach którym te dane mogą być przekazywane, okresie
        czasu przez który dane osobowe będą przechowywane jak również inne wymagane
        prawem informacje dostępne są w dokumencie „
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <a href={`${WWW_URL}/regulamin${getSearchParams()}&rules=polityka_prywatnosci`} target="_blank" rel="noopener noreferrer">„POLITYKA PRYWATNOŚCI"</a>
      </p>
    </div>
  </footer>
);
