import React from 'react';
import styles from '../../styles/scss/libraries/bootstrap.module.scss';
import { CALC_URL } from './url';
import { getSearchParams } from './utils';

export const MainSection = () => (
  <div className="main-section">
    <div className="bg">
      <div className={`${styles.container} cont`}>
        <div className={styles.row}>
          <div className={`${styles.colLg6} ${styles.colLgOffset1} ${styles.colMd6} ${styles.colMdOffset5}`}>
            <div className="flex-column">
              <div className="title">
                <h1 style={{ fontSize: '42px', margin: 0 }}>Wybierz w końcu</h1>
                <h2 className="green-bold mautic-subtitle">TANIE OC</h2>
              </div>
              <img
                style={{ width: '146px', height: 'auto' }}
                className="img-text" src="/lp/arrow21-o.png" alt="Porównaj sam!"
              />
              <div className="button-wrapper">
                <a href="#formularz" className="button btn btn-default">
                  Rozmawiaj z Agentem
                </a>
              </div>
              <div className="button-wrapper" style={{ marginTop: 0 }}>
                <a href={`${CALC_URL}/${getSearchParams()}`} target="_blank" rel="noreferrer noopener" className="button btn btn-default">
                  Oblicz samodzielnie
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
