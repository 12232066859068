export const regPpl = 'REG_PPL' as const;
export const markTel = 'MARK_TEL' as const;

export const REQUIRED_AGREEMENTS = [regPpl, markTel];

export type AgrementNames = typeof regPpl | typeof markTel;

const AGREEMENT_ORDER = {
  REG_PPL: 1,
  MARK_TEL: 2,
};

export const getAgreementName = (agreementName: keyof typeof AGREEMENT_ORDER) => (AGREEMENT_ORDER[agreementName]);
