import React from 'react';
import styles from '../../styles/scss/libraries/bootstrap.module.scss';

export const LogoSection = () => (
  <div className="logo-section">
    <div className={styles.container}>
      <div className="logo-flex">
        <img src="/lp/logo-color.png" alt="Porowneo.pl" className="logo" />
      </div>
    </div>
  </div>
);
