/* eslint-disable import/no-default-export,no-useless-escape,react/jsx-props-no-spreading,react/no-danger,jsx-a11y/label-has-associated-control */
import React from 'react';
import {
  withFormik, FormikProps,
} from 'formik';
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';

import styles from '../../styles/scss/libraries/bootstrap.module.scss';
import { FormValues, MyFormProps, OtherProps } from './types';
import { createPayload, isPhoneHLR, setMask } from './helpers';
import { markTel, regPpl } from './config';
import { ktApi } from './url';

const ErrorInfo = ({ children }: { children: React.ReactNode}) => <div style={{ color: 'red', marginTop: '.5rem' }}>{children}</div>;

const InnerForm = (props: OtherProps & FormikProps<FormValues>) => {
  const {
    values,
    errors,
    touched,
    status,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    agreements,
    openModal,
  } = props;

  const regAgreement = agreements.find(agreement => agreement.name === regPpl);
  const telAgreement = agreements.find(agreement => agreement.name === markTel);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="mauticform-innerform">
          <div className="mauticform-page-wrapper mauticform-page-1">
            <div className="mauticform-row mauticform-freetext mauticform-field-1">
              <h3 className="mauticform-label form-header">
                WYPEŁNIJ FORMULARZ KONTAKTOWY
              </h3>
              <div className="mauticform-freetext" />
            </div>

            <div className="mauticform-row mauticform-text mauticform-field-2 mauticform-required">
              <label
                id="forName"
                htmlFor="full_name_input"
                className="mauticform-label"
              >
                Imię
              </label>

              <MaskedInput
                id="full_name"
                name="full_name"
                mask={setMask(25, /[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]/)}
                guide={false}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.full_name}
                render={(ref, maskedProps) => (
                  <input ref={ref} {...maskedProps} id="full_name_input" className="mauticform-input" />
                )}
              />
              {touched.full_name && errors.full_name && <ErrorInfo>{errors.full_name}</ErrorInfo>}
            </div>

            <div className="mauticform-row mauticform-tel mauticform-field-3 mauticform-required">
              <label htmlFor="phoneInput" className="mauticform-label">Telefon</label>

              <MaskedInput
                id="phone"
                name="phone"
                mask={setMask(9, /\d/)}
                guide={false}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                render={(ref, maskedProps) => (
                  <input ref={ref} {...maskedProps} id="phoneInput" className="mauticform-input" />
                )}
              />

              {touched.phone && errors.phone && <ErrorInfo>{errors.phone}</ErrorInfo>}
            </div>

            <div className="mauticform-row mauticform-checkboxgrp mauticform-field-5 mauticform-required mautic-agreements">
              {regAgreement && (
                <div className="mauticform-checkboxgrp-row" style={{ order: regAgreement?.order }}>
                  <div className="mautic-checkbox-wrapper">
                    <label
                      className="mauticform-checkboxgrp-label"
                      htmlFor={regAgreement.name}
                      dangerouslySetInnerHTML={{ __html: regAgreement.description }}
                    />
                    <input
                      type="checkbox"
                      id={regAgreement.name}
                      name={regAgreement.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      checked={values.REG_PPL}
                      className="mauticform-checkboxgrp-checkbox mautic-checkbox-wrapper__input"
                    />
                  </div>
                  {touched.REG_PPL && errors.REG_PPL && <ErrorInfo>{errors.REG_PPL}</ErrorInfo>}

                </div>)}
              {telAgreement && (
                <div className="mauticform-checkboxgrp-row" style={{ order: telAgreement?.order }}>
                  <div className="mautic-checkbox-wrapper">
                    <label
                      className="mauticform-checkboxgrp-label"
                      htmlFor={telAgreement.name}
                      dangerouslySetInnerHTML={{ __html: telAgreement.description }}
                    />
                    <input
                      type="checkbox"
                      id={telAgreement.name}
                      name={telAgreement.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      checked={values.MARK_TEL}
                      className="mauticform-checkboxgrp-checkbox mautic-checkbox-wrapper__input"
                    />
                  </div>
                  {touched.MARK_TEL && errors.MARK_TEL && <ErrorInfo>{errors.MARK_TEL}</ErrorInfo>}
                </div>)}
            </div>

            <div className="mauticform-row mauticform-button-wrapper mauticform-field-7 centerMe">
              <button
                className={`mauticform-button ${styles.btn} ${styles.btnDefault} mauticform-button--custom`}
                type="submit"
                disabled={
                  isSubmitting ||
                  !!(errors.phone && touched.phone) ||
                  !!(errors.full_name && touched.full_name) ||
                  !!(errors.MARK_TEL && touched.MARK_TEL) ||
                  !!(errors.REG_PPL && touched.REG_PPL)
                }
              >
                Zamów rozmowę
              </button>
            </div>

            {status === 'sended' && openModal()}

            {status === 'error' &&
            <div>
              <ErrorInfo>
                Coś poszło nie tak, spróbuj ponownie lub daj nam proszę znać, że masz problemy
                kontakt@porowneo.pl
              </ErrorInfo>
            </div>}
          </div>
        </div>
      </form>
    </div>
  );
};

const ContactForm = withFormik<MyFormProps, FormValues>({
  mapPropsToValues: props => ({
    full_name: props.initialName || '',
    phone: props.initialPhone || '',
    REG_PPL: false,
    MARK_TEL: false,
  }),

  validationSchema: Yup.object().shape({
    full_name: Yup.string()
      .min(3, 'Wartość zbyt krótka.')
      .required('Pole imię jest wymagane.'),
    phone: Yup.string()
      .min(9, 'Niepoprawny format numeru telefonu. Prosimy o weryfikację.')
      .required('Pole telefon jest wymagane.')
      .test('checkEmailUnique', 'Niepoprawny numer telefonu.', async value => isPhoneHLR(value)),
    REG_PPL: Yup
      .boolean()
      .oneOf([true], 'Zapoznanie się z regulaminem jest konieczne.'),
    MARK_TEL: Yup
      .boolean()
      .oneOf([true], 'Wyrażenie zgody jest dobrowolne. Jednocześnie informujemy, że jest ona warunkiem kontaktu.'),
  }),

  async handleSubmit(
    {
      full_name, phone, REG_PPL, MARK_TEL,
    }: FormValues,
    {
      setSubmitting, setStatus, resetForm, props: { agreements },
    },
  ) {
    const payload = createPayload(
      full_name,
      phone,
      { REG_PPL, MARK_TEL },
      agreements,
    );
    const apiResponse = await ktApi.post('/landing/engine', payload);

    if (apiResponse && apiResponse.status === 'success') {
      if (apiResponse?.cbz_id) sessionStorage.setItem('CBZ_ID', apiResponse.cbz_id);
      resetForm();
      setStatus('sended');
    } else {
      setStatus('error');
    }

    setSubmitting(false);
  },
})(InnerForm);

export default ContactForm;
