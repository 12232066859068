import { useEffect, useRef, useReducer } from 'react';

const ACTION_TYPES = {
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
  FETCH_ERROR: 'FETCH_ERROR',
} as const;

interface IAction {
  payload?: any;
  type: keyof typeof ACTION_TYPES;
}

function useFetch <T extends unknown>(url: string): T {
  const cache = useRef<{ [key: string]: any }>({});

  const initialState = {
    status: 'idle',
    error: null,
    data: [],
  } as const;

  const reducer = (state: any, action: IAction) => {
    switch (action.type) {
    case 'FETCHING':
      return { ...initialState, status: 'fetching' };
    case 'FETCHED':
      return { ...initialState, status: 'fetched', data: action.payload };
    case 'FETCH_ERROR':
      return { ...initialState, status: 'error', error: action.payload };
    default:
      return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    let cancelRequest = false;
    if (!url) return;

    const fetchData = async () => {
      dispatch({ type: 'FETCHING' });
      if (cache.current[url]) {
        const data = cache.current[url];
        dispatch({ type: 'FETCHED', payload: data });
      } else {
        try {
          const response = await fetch(url);
          const data = await response.json();
          cache.current[url] = data;
          if (cancelRequest) return;
          dispatch({ type: 'FETCHED', payload: data });
        } catch (error) {
          if (cancelRequest) return;

          dispatch({ type: 'FETCH_ERROR', payload: error?.message });
        }
      }
    };

    fetchData();

    return () => {
      cancelRequest = true;
    };
  }, [url]);

  return state;
}

export default useFetch;
