/* eslint-disable no-useless-escape */
export function getCookieByName(name: string) {
  const cookieArr = document.cookie.split(';');
  for (let i = 0; i < cookieArr.length; i += 1) {
    const cookiePair = cookieArr[i].split('=');
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}

export function getParameterByName(name: string) {
  if (typeof window !== 'undefined') {
    const url = window.location.href;
    const regex = new RegExp(`[?&]${name.replace(/[\[\]]/g, '\\$&')}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  return '';
}

export const getSearchParams = () => {
  if (typeof window === 'undefined') {
    return '';
  }
  return window.location.search;
};
