/* eslint-disable import/no-default-export */
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import styles from '../styles/scss/libraries/bootstrap.module.scss';
import '../styles/scss/pages/porownaj-oc-ac.scss';
import ContactForm from '../components/LP/ContactForm';
import useFetch from '../utils/hooks/useFetch';
import { Modal } from '../components/LP/Modal';
import { IFetchedData } from '../components/LP/types';
import { createUrlGetAgreements, CALC_URL } from '../components/LP/url';
import { setAgreementsOrder } from '../components/LP/helpers';
import { Logos } from '../components/LP/Logos';
import { FooterLp } from '../components/LP/FooterLp';
import { MainSection } from '../components/LP/MainSection';
import { LogoSection } from '../components/LP/LogoSection';
import { getSearchParams } from '../components/LP/utils';

export default () => {
  const agreementsData = useFetch<IFetchedData>(createUrlGetAgreements());
  const [isModalShown, toogleModal] = useState<boolean>(false);

  const openModal = () => toogleModal(true);

  return (
    <>
      <Helmet title="Porównaj OC, AC | swiatubezpieczen.com" />

      {
        false && (agreementsData.status === 'idle' || agreementsData.status === 'fetching') &&
          <div>Loading...</div>

      }
      {
        agreementsData.status === 'error' && (
          <div style={{ color: 'red', marginTop: '.5rem' }}>
            Coś poszło nie tak, spróbuj ponownie lub daj nam proszę znać, że masz problemy
            kontakt@porowneo.pl
          </div>)
      }

      <div className={`ui-sortable ${isModalShown ? 'mautic-no-scroll' : ''}`} style={{ overflow: 'visible', cursor: 'auto' }}>
        <div>
          <LogoSection />
          {
            agreementsData.status === 'fetched' && (
              <>
                <MainSection />
                <div className="form-section" id="formularz">
                  <div className={`${styles.container} container-fixed form-wrapper`}>
                    <div className="title">
                      <h1>
                        <b>Masz pytania?</b>
                        <br />
                        Zamów rozmowę z naszym Agentem!
                      </h1>
                    </div>
                    <div id="mauticform_wrapper_gadsformshort" className="mauticform_wrapper">

                      <ContactForm
                        agreements={setAgreementsOrder(agreementsData.data)}
                        openModal={() => openModal()}
                      />
                    </div>
                    <div className="button-wrapper centerMe">
                      lub
                    </div>
                    <div style={{ marginTop: '0px !important' }} className="button-wrapper centerMe">
                      <a
                        href={`${CALC_URL}/${getSearchParams()}`}
                        className={`button ${styles.btn} ${styles.btnDefault} mauticform-button--custom`}
                      >
                        Oblicz samodzielnie
                      </a>
                    </div>
                  </div>
                </div>
              </>
            )
          }

          <Logos />
          <FooterLp />
        </div>
      </div>
      <Modal isModalShown={isModalShown} />
    </>
  );
};
